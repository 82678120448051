div.icon-container {
    display: flex;
    background-color: gold;
    margin: 20px;
    padding: 5px;
    padding-bottom: 10px;
    max-width: 270px;
    box-shadow: 5px 5px goldenrod;
}
.leftpart {
    margin-left: 5px;
}

div {
    font-family: Verdana, Geneva, Tahoma, sans-serif;
    font-size: 12px;
}

img {
    height: 70%;
    margin: 5px 0px 10px 5px;
    border: 2px solid WHITE;
}

.goods_name {
    font-weight: 900;
}

.rating {
    display: inline-block;
    background-color: white;
    padding: 0px 5px 3px 5px;
    margin: 0px 0px 0px 10px;
    border-radius: 15px;
    font-size: 18px;
}

.stars {
    color: darksalmon;
}

.ratedImage {
    display: flex;
    align-items: center;
}
label {
    display: inline-block;
    width: 100%;
    margin: 2px 0px;
    font-weight: bold;
}
input {
    width: 100%;
    margin: 5px 5px 5px 0px;

}

form {
    margin: 20px;
    padding: 20px 10px;
}

input[type=submit] {
    margin-top:  20px ;

}

p {
    color: maroon;
    background-color: beige;
    margin: 5px;

}